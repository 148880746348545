<template>
  <div>
    <vue-excel-xlsx
      class="btn btn-primary"
      :data="data"
      :columns="columns"
      :file-name="'filename'"
      :file-type="'xlsx'"
      :sheet-name="'sheetname'"
    >
      Download
    </vue-excel-xlsx>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [],
      columns2: [
        {
          label: "Product",
          field: "product",
        },
        {
          label: "Price",
          field: "price",
          dataFormat: this.priceFormat,
        },
        {
          label: "Quantity",
          field: "quantity",
        },
        {
          label: "Country",
          field: "address.country",
        },
        {
          label: "City",
          field: "address.city",
        },
      ],
      data: [],
      data2: [
        {
          product: "Beverage",
          price: 10,
          quantity: 2,
          address: {
            country: "Armenia",
            city: "Yerevan",
          },
        },
        {
          product: "Snack",
          price: 12,
          quantity: 6,
          address: {
            country: "Armenia",
            city: "Yerevan",
          },
        },
        {
          product: "Beverage",
          price: 10,
          quantity: 5,
          address: {
            country: "Armenia",
            city: "Yerevan",
          },
        },
        {
          product: "Snack",
          price: 12,
          quantity: 3,
          address: {
            country: "Armenia",
            city: "Yerevan",
          },
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    priceFormat(value) {
      if (value == null) return "-";
      else return value;
    },
    async initialize() {
      await this.$axios
        .get("/dashboard/course/" + "1647833493011101" + "/assistance-report", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.data = res.data.allAssistancePersonDto;
          this.addHeader(res.data.allDate);
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    addHeader(values) {
      this.columns.push(
        {
          label: "NOMBRES",
          field: "name",
        },
        {
          label: "APELLIDO PATERNO",
          field: "lastnameFather",
        },
        {
          label: "APELLIDO MATERNO",
          field: "lastnameMother",
        }
      );
      for (const value in values) {
        this.columns.push({
          label: values[value],
          field: "allAssistance[" + value + "].status",
          dataFormat: this.priceFormat,
        });
      }
      console.log("termino");
    },
  },
};
</script>